import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AppService } from '../app/app.service';
import { HttpService } from '../http/http.service';
import { DateService } from '../dates/date.service';
import { ICreateReservationPaxDTO, ICreateReservationProductDTO } from 'src/app/Interfaces/Requests/CreateReservationRequestDTO';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrencyService } from '../currency/currency.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ShopcartService } from '../shopcart/shopcart.service';
import { IReservationDTO } from 'src/app/Interfaces/Responses/GetReservationByIdResponse.dto';
import { AuthService } from '../auth/auth.service';
import { IShopcart } from 'src/app/models/Shopcart.model';
import { ProductTypeEnum } from 'src/app/models/enums/ProductType.enum';
import { IClient } from 'src/app/models/Client.model';
import { BookingService } from '../booking/booking.service';
import { RouterService } from '../router/router.service';
import { IReservation } from 'src/app/models/Reservation.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private reservationId: string = "";
  private reservationIdSubject: BehaviorSubject<string> = new BehaviorSubject(this.reservationId);
  public reservationObservable$: Observable<string> = this.reservationIdSubject.asObservable();

  private reservation: IReservation | null = null;
  private reservationSubject = new BehaviorSubject<IReservation | null>(this.reservation);
  public reservation$ = this.reservationSubject.asObservable();

  public limitForInventory: number = 0;

  constructor(
    private appService: AppService,
    private httpService: HttpService,
    private dateService: DateService,
    private shopcarService: ShopcartService,
    private currencyService: CurrencyService,
    private firebaseRealTimeService: AngularFireDatabase,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {}

  public setReservationId(reservationId: string) {
    this.reservationId = reservationId;
    this.reservationIdSubject.next(reservationId);
  }

  public getReservationId() {
    // console.log('%c ReservationService.getReservationId', 'color: blue;');
    // console.log(
    //   '%c ' + JSON.stringify(this.reservationIdSubject.value),
    //   'color: blue;'
    // );
    return this.reservationIdSubject.value;
  }

  public async getReservation(reservationId: string): Promise<IReservationDTO> {
    try {
      const { data } = await this.httpService.getReservationById(reservationId);
      return data;
    } catch (error) {
      throw false;
    }
  }

  public async createReservation(shopcart: IShopcart, clientForm: IClient) {
    const lang = this.appService.getCurrentLang();
    let productList: ICreateReservationProductDTO[] = [];
    let checkInDate = this.dateService.toString(shopcart.checkInDate);
    let totalPaxes = 0;

    let productsOutOfStock = [];
    let isCorrect: boolean = true;

    for (let product of shopcart.productList) {
      let productToSave: ICreateReservationProductDTO = {
        productId: product.id,
        productVariant: product.variant,
        locationId: product.locationId,
        inventoryId: product.schedule?.inventoryId as number,
        scheduleId: product.schedule?.id as number,
        hotelId: 0,
        date: null,
        transportationDropOff: null,
        transportationPickUp: null,
        paxList: []
      }

      const { data, length } = await this.httpService.getScheduleList({
        locationId: productToSave.locationId,
        productId: productToSave.productId,
        checkInDate:
          this.datePipe.transform(shopcart.checkInDate, 'yyyy-MM-dd') ?? '',
      });

      const hasInventary = data.find(
        (i) => i.invetoryId == productToSave.inventoryId
      );
      const totalPax = product.paxList.reduce((j, k) => j + k.quantity, 0);

      if (hasInventary && (totalPax > hasInventary.availableInventory)) {
        isCorrect = false;
        productsOutOfStock.push({ product });
        this.limitForInventory = hasInventary.availableInventory;
      }

      // console.group(`createReservation.validate.inventory.by.productId[${productToSave.productId}]`);
      // console.log('data', data);
      // console.log('hasInventary', hasInventary);
      // console.log('totalPax', totalPax);
      // console.log('isCorrect', isCorrect);
      // console.log('productsOutOfStock', productsOutOfStock);

      let paxList: ICreateReservationPaxDTO[] = product.paxList.map((pax) => {
        totalPaxes += pax.quantity;

        return {
          paxId: pax.id,
          paxGeneralId: pax.generalId ?? 0,
          paxPrice: pax.price,
          paxTotal: pax.total,
          paxQuantity: pax.quantity,
          paxDiscount: pax.discount ?? 0,
          paxSubtotal: pax.subtotal,
          paxDiscountTotal: pax.discountTotal ?? 0,
        }
      });
      productToSave.paxList = [...paxList];
      productList.push(productToSave);
    }

    // add the addons
    for (let product of shopcart.addonList) {
      let productToSave: ICreateReservationProductDTO = {
        productId: product.addonId,
        productVariant: ProductTypeEnum.SINGLE,
        locationId: product.locationId,
        inventoryId: 0,
        scheduleId: 0,
        hotelId: 0,
        date: null,
        transportationDropOff: null,
        transportationPickUp: null,
        paxList: []
      }

      let paxList: ICreateReservationPaxDTO[] = product.paxList.map(pax => {
        totalPaxes += pax.paxQuantity;
        return {
          paxId: pax.paxId,
          paxPrice: pax.paxPrice,
          paxTotal: pax.paxTotal,
          paxQuantity: pax.paxQuantity,
          paxDiscount: pax.paxDiscount ?? 0,
          paxSubtotal: pax.paxSubtotal,
          paxDiscountTotal: pax.paxDiscount
        }
      })
      productToSave.paxList = [...paxList];
      productList.push(productToSave);
    }

    if (shopcart.transportation) {
      let productToSave: ICreateReservationProductDTO = {
        productId: shopcart.transportation.id,
        productVariant: ProductTypeEnum.SINGLE,
        locationId: shopcart.transportation.locationId,
        inventoryId: 0,
        scheduleId: 0,
        hotelId: shopcart.transportation.hotelId,
        date: this.dateService.toString(shopcart.transportation.pickUpFrontEnd),
        transportationDropOff: this.dateService.toString(shopcart.transportation.dropOff),
        transportationPickUp: this.dateService.toString(shopcart.transportation.pickUp),
        paxList: [],
      };

      let paxList: ICreateReservationPaxDTO[] = shopcart.transportation.paxList.map(pax => {
        totalPaxes += pax.quantity;
        return {
          paxId: pax.id,
          paxGeneralId: pax.generalId ?? 0,
          paxPrice: pax.price,
          paxTotal: pax.total,
          paxQuantity: pax.quantity,
          paxDiscount: pax.discount ?? 0,
          paxSubtotal: pax.subtotal,
          paxDiscountTotal: pax.discountTotal ?? 0,
        }
      })
      productToSave.paxList = [...paxList];
      productList.push(productToSave);
    }

    if (isCorrect) {
      const { data, length } = await this.httpService.createReservation({
        checkInDate: checkInDate,
        clientName: clientForm.name,
        clientLastname: clientForm.lastName,
        clientEmail: clientForm.email,
        clientComments: clientForm.comments,
        clientPhone: clientForm.phone,
        clientAddress: clientForm.address,
        clientCountry: clientForm.country,
        clientState: clientForm.state,
        lang: lang,
        productList: productList,
        reservationTotal: shopcart.shopcartTotal,
        reservationPaxTotal: totalPaxes,
        discountId: shopcart.discountId ?? null,
        comboId: shopcart.comboId ?? null,
        hotelId: shopcart.hotelId,
        dynamicDiscount: this.shopcarService.getDynamicDiscount()??0.0,
        dynamicDiscountString: this.shopcarService.getDynamicDiscountString()??'',
      });

      return data.reservationId;
    }

    return null;
  }

  public async setUpReservation(reservationId: string) {
    // console.log('%c ReservationService.setUpReservation', 'color: blue;');

    const { data } = await this.httpService.getReservationById(reservationId, true);
    let reservation = data;

    this.appService.setTerms(reservation.termsConditionsUrl);
    this.appService.setPolicy(reservation.privacyPolicyUrl);
    this.appService.setLocation(reservation.locationId, reservation.locationName);
    this.appService.setLang(reservation.reservationLang);
    this.appService.setCurrency(reservation.currencyCode, reservation.currencyId);
    this.appService.setLogoUrl(reservation.reservationLogoUrl);

    this.authService.setToken(reservation.authToken);
    this.currencyService.setCurrency({
      id: reservation.currencyId,
      symbol: reservation.currencyCode
    });

    // console.log('%c' + JSON.stringify(reservation), 'color: blue;');

    // this.shopcarService.setDiscountCode(reservation.discountId, reservation.discountCode);

    reservation.productList.forEach(product => {
      let productIndex = this.shopcarService.addProduct(
        product.productId,
        product.productName,
        1,
        "idk",
        ProductTypeEnum.SINGLE,
        product.productImage
      );

      this.shopcarService.addSchedule(product.productId, {
        id: 0, inventoryId: 0,
        date: this.dateService.stringToDate(product.productCheckInDate),
        dateEnd: this.dateService.stringToDate(product.productCheckInDate)
      })

      product.paxList.forEach(pax => {
        this.shopcarService.addPax(product.productId,
          {
            id: pax.paxId,
            name: pax.paxName,
            price: pax.paxPrice,
            quantity: pax.paxQuantity,
            total: pax.paxTotal,
            priority: 0,
            blocked: false,
            discount: pax.paxDiscount,
            subtotal: pax.paxSubtotal,
          }
          , productIndex);
      })
    });

    this.reservationSubject.next({
      reservationId: reservation.reservationId,
      clientEmail: reservation.clientEmail,
      clientName: reservation.clientName
    })

    return reservation.reservationStatus;
  }
}
