import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { IDynamicPriceListReponseDTO } from 'src/app/Interfaces/Responses/IDynamicPriceListResponseDTO';
import { AppService } from 'src/app/services/app/app.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ShopcartService } from 'src/app/services/shopcart/shopcart.service';

@Component({
  selector: 'app-calendar-price',
  templateUrl: './calendar-price.component.html',
  styleUrls: ['./calendar-price.component.css']
})

export class CalendarPriceComponent implements OnInit {
  @Output('onCheckInDateSelected') onCheckInDateSelected : EventEmitter<Date> = new EventEmitter();
  @Input('onProductId') onProductId: number=0;

  public currentDate: Date = new Date;
  public pricesPerDay: { [key: string]: number };
  public displayedMonth: number;  // Mes mostrado (0-11)
  public displayedYear: number;   // Año mostrado
  //private listDiscount: { [key: string]: number };
  private listDiscount: { [key: string]: {discount:number,discountString:string} };

  constructor(
    private httpService: HttpService,
    private shopcartService: ShopcartService,
    private appService: AppService,
  ) {
    const now = new Date();
    this.displayedMonth = now.getMonth();  // Mes actual
    this.displayedYear = now.getFullYear(); // Año actual
    this.pricesPerDay = {};
    this.listDiscount = {};
    //this.getPriceForDate();
  }

  ngOnInit(): void {
    //this.renderCalendar();
    this.getPriceForDate().then(() => {
      this.renderCalendar(); // Asegura que el calendario se renderice después de cargar los precios
    });
  }

  // Método para obtener el primer día del mes
  getFirstDayOfMonth(): Date {
    const date = new Date(this.displayedYear, this.displayedMonth, 1);
    return date;
  }

  // Método para obtener el número total de días en el mes
  getDaysInMonth(): number {
    const date = new Date(this.displayedYear, this.displayedMonth + 1, 0);
    return date.getDate();
  }

  // Método para navegar al mes anterior
  goToPreviousMonth(): void {
    if (this.displayedMonth === 0) {
      this.displayedMonth = 11;
      this.displayedYear--;
    } else {
      this.displayedMonth--;
    }
    //this.renderCalendar(); // Actualizar el calendario
    this.getPriceForDate().then(() => {
      this.renderCalendar(); // Asegura que el calendario se renderice después de cargar los precios
    });
  }

  // Método para navegar al siguiente mes
  goToNextMonth(): void {
    if (this.displayedMonth === 11) {
      this.displayedMonth = 0;
      this.displayedYear++;
    } else {
      this.displayedMonth++;
    }
    //this.renderCalendar(); // Actualizar el calendario
    this.getPriceForDate().then(() => {
      this.renderCalendar(); // Asegura que el calendario se renderice después de cargar los precios
    });
  }

  private createdWeekDays(calendarElement:HTMLElement):void{
    const weekdays = this.getWeekDays();

    weekdays.forEach(day => {
      const dayElement = document.createElement('div');
      dayElement.classList.add('day', 'weekdays');
      dayElement.style.pointerEvents = 'none'; // Hace que no sea clickeable
      dayElement.textContent = day;
      calendarElement.appendChild(dayElement);
    });
  }

  // Método para generar el calendario
  renderCalendar():void {
    //this.getPriceForDate();
    const calendarElement = document.getElementById('calendar-body');
    if (!calendarElement) return;

    calendarElement.innerHTML = ''; // Limpiar contenido previo

    // Crear el encabezado con el mes y el año
    /*const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    const headerElement = document.createElement('div');
    headerElement.classList.add('calendar-header');
    headerElement.innerHTML = `
      <button (click)="goToPreviousMonth()">&#8592;</button>
      <span>${monthNames[this.displayedMonth]} ${this.displayedYear}</span>
      <button (click)="goToNextMonth()">&#8594;</button>
    `;
    calendarElement.appendChild(headerElement);*/

    // Crear los días de la semana
    this.createdWeekDays(calendarElement);

    const tomorrow = new Date(); // Obtener la fecha de hoy
    tomorrow.setDate(tomorrow.getDate()+1); //Agrega un día más
    tomorrow.setHours(0, 0, 0, 0); // Normalizar la fecha de hoy sin hora

    //Desactivar botón de meses pasado
    this.setButtonDisabledById('previous-month',false);
    if(this.displayedYear<=tomorrow.getFullYear() && this.displayedMonth<=tomorrow.getMonth())
    {
      //console.log('Mes no disponible');
      this.setButtonDisabledById('previous-month',true);
    }

    // Generar los días del mes
    const firstDay = this.getFirstDayOfMonth();
    const totalDays = this.getDaysInMonth();
    const startDay = firstDay.getDay(); // Día de la semana en que empieza el mes

    // Llenar los espacios en blanco antes de empezar el mes
    for (let i = 0; i < startDay; i++) {
      const emptyDay = document.createElement('div');
      emptyDay.classList.add('blocked-day');
      emptyDay.style.pointerEvents = 'none'; // Hace que no sea clickeable
      emptyDay.style.opacity = '0.5'; // Hace que se vea más opaco
      calendarElement.appendChild(emptyDay);
    }

    // Crear los días del mes
    for (let i = 1; i <= totalDays; i++) {
      const currentDay = new Date(this.displayedYear, this.displayedMonth, i);
      const dayString = currentDay.toISOString().split('T')[0]; // 'YYYY-MM-DD'
      const dayPrice = this.pricesPerDay[dayString] || ''; // Si no hay precio, mostramos "Sin precio"

      const dayElement = document.createElement('div');
      dayElement.classList.add('day');
      
      // Crear un div para el día y otro para el precio
      const dayText = document.createElement('div');
      dayText.textContent = `${i}`;
      const priceText = document.createElement('div');
      if(dayPrice!='')
      priceText.textContent += '$';
      priceText.textContent += `${dayPrice}`;
      
      dayElement.appendChild(dayText); // Día
      if(dayPrice!='')
      dayElement.appendChild(priceText); // Precio

      // Comparar si la fecha ya pasó
      if (currentDay < tomorrow || dayPrice == '') {
        dayElement.classList.remove('day');
        dayElement.classList.add('blocked-day'); // Agrega una clase para darle un estilo diferente
        dayElement.style.pointerEvents = 'none'; // Hace que no sea clickeable
        dayElement.style.opacity = '0.5'; // Hace que se vea más opaco
      }

      // Evento de click para cada día
      dayElement.addEventListener('click', () => {
        //alert(`Día seleccionado: ${i}\nPrecio: $${dayPrice}`);
        let dateSelect: Date = new Date(this.displayedYear,this.displayedMonth,i);
        let discount = this.listDiscount[dateSelect.toISOString().split('T')[0].toString()];
        //alert(`Fecha seleccionada: ${dateSelect}`);
        this.onCheckInDateSelected.emit(dateSelect);
        //this.shopcartService.setDynamicDiscount(this.listDiscount[dateSelect.toISOString().split('T')[0].toString()]);
        this.shopcartService.setDynamicDiscount(discount['discount']);
        this.shopcartService.setDynamicDiscountString(discount['discountString']);
      });

      calendarElement.appendChild(dayElement);
    }
  }

  private getWeekDays(): string[] {
    
    let lang = this.appService.getCurrentLang();
    let weekdays = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

    if(lang=='en')
      weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekdays;
  }

  getMonthName(): string {
    const lang = this.appService.getCurrentLang();
    let monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    if(lang == 'en')
      monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

    return monthNames[this.displayedMonth];
  }

  private setButtonDisabledById(id:string,disabled:boolean)
  {
    const btnElement = document.getElementById(id) as HTMLButtonElement;
    btnElement.disabled = disabled;
  }

  async getPriceForDate():Promise<void>{
    let dateStart: Date = this.getFirstDayOfMonth();
    let numDays: number = this.getDaysInMonth();
    let dateEnd: Date = new Date(this.displayedYear,this.displayedMonth,numDays);
    this.setButtonDisabledById('previous-month',true);
    this.setButtonDisabledById('next-month',true);

    let now = new Date();
    now.setDate(now.getDate()+1);
    if(now.getFullYear()==this.displayedYear && now.getMonth()==this.displayedMonth)
      dateStart = now;
    //console.log('Fecha inicio',dateStart.toISOString());
    //console.log('Fecha final',dateEnd.toISOString());
    //console.log('Producto id',this.onProductId);
    let listResponse:IDynamicPriceListReponseDTO = await this.httpService.getDynamicPriceList({
      /*productId: booking.principalProductId,
      discountCode: discountCode,*/
      productId: this.onProductId,
      dateStart: `${dateStart.toISOString().split('T')[0].toString()} 00:00:00`,
      dateEnd: `${dateEnd.toISOString().split('T')[0]} 00:00:00`,
    });
    //console.log('precios fijo',this.pricesPerDay);
    //console.log('lista',listResponse);
    /*listResponse.data.map((priceList)=>
    {
      console.log(`pax price ${priceList.paxPrice}`);
    }
    );*/
    this.pricesPerDay = {};
    this.listDiscount = {};
    listResponse.data.forEach(priceList => {
      //console.log(`${priceList.dateInventory} : ${priceList.paxPriceDynamic}`);
      this.pricesPerDay[priceList.dateInventory.split(' ')[0]] = Number(priceList.paxPriceDynamic);
      this.listDiscount[priceList.dateInventory.split(' ')[0]] = {
        'discount':Number(priceList.discount),'discountString':String(priceList.stringDiscount)
      };
    })
    /*this.pricesPerDay = listResponse.data.reduce((acc:any, priceList) => {
      acc[priceList.dateInventory] = priceList.paxPriceDynamic;
      return acc;
    }, {});*/
    //console.log('precios',this.pricesPerDay);
    //console.log('listDiscount',this.listDiscount);
    this.setButtonDisabledById('previous-month',false);
    this.setButtonDisabledById('next-month',false);
  }
}