export const environment = {
  production: true,
  MAIN_URL: 'https://ebsicr.venturapark.com/api/',
  ZONES: {
    GET_ZONES: 'zones',
  },
  HOTELS: {
    GET_HOTELS: 'hotels',
  },
  TRANSPORTATION: {
    GET: 'web/transportations',
  },
  BANKS: {
    PAYPAL: 'payment/paypal',
     FRONT_DESK: 'payment/frontdesk',
    CONFIRMATION: '{reservationId}/confirmation',  

 },
  ADDONS: {
    TRASNPORTATION: {
      LOCATIONS: [
	2, // Cancun
        4, // Riviera Maya
	8, //'Selvatica'
        //7, //'Ventura Park'
      ],
    },
     ADO: 317,
  },
  
  BANKS_AVAILABLE: {
    LOCATIONS: {
      2: ['MIT*', 'PAYPAL'], // Cancun
      4: ['MIT*', 'PAYPAL'], // Riviera Maya    
      7: ['MIT', 'PAYPAL'], //'Ventura Park'
      8: [ 'PAYPAL'], //'Selvatica'
      10: ['MIT*','PAYWORKS*' , 'PAYPAL', 'FRONT-DESK'], // Selva Magica
    },
  }, 

 URL_SELVATICA: 'https://www.selvatica.com.mx',
  URL_VENTURA: 'https://www.venturapark.com',
 URL_CANCUN: 'https://dolphinconnectionmx.com/',
URL_RIVIERA_MAYA: 'https://dolphinconnectionmx.com/',
URL_SELVA_MAGICA: 'https://www.selvamagica.com.mx/',
  MAX_PAX: 20,
  
   BOOKING_TRAVEL: {
    LOCATIONS: {
      7: 0, //'Ventura Park'
      8: 0, //'Selvatica'
      10: 0, // Selva Magica
    },
  }, 

 MAIN_PAX: 2,
TOTAL_MIN_PAYMENT: {
    2: {
      // Cancun
      LINK: {
        USD: 30,
        MXN: 399,
      },
    },
   4: {
      // Riviera Maya
      LINK: {
        USD: 30,
        MXN: 399,
      },
    },
    7: {
      // Ventura Park
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
    8: {
      // Selvatica
      LINK: {
        USD: 20,
        MXN: 399,
      },
    },
    10: {
      // Selva Magica
      LINK: {
        USD: 10,
        MXN: 199,
      },
    },
  }, 


WIZARD: {
    // 2: {
    //   // Cancun
    // },
    // 4: {
    //   // Riviera Maya
    // },
    // 7: {
    //   // Ventura Park
    // },
    // 8: {
    //   // Selvatica
    // },
    10: {
       // Selva Magica
      HIDE: ['ADDON'],
     },
  },


 PAYPAL_MODE: 'live',
  PAYPAL_SANDBOX_CLIENT_ID: 'Aa4eaI5fBGlwvRTzzM9M3hzkksni2SGJeO-buvGnG7ywVkmJqZt2xDPWYfa__ipjaKdeCnhJttdWJHHV',
  PAYPAL_LIVE_CLIENT_ID: 'ASkZ9kRcxXTWyjASCcdoGo-YXkO3ESUNs680nL5rUinC2tXVnkCXYhatvmrCX38KNR6kLaBIZCVzuBNw',
  PAYPAL_LIVE_CLIENT_SECRET: 'EImvoA-OoOaQGeAxbUZj_MDG0YaHHXQmoNTNjK-cV7YMhmy0pEvHphnnWuWNY3pB5XzPZsKpO-HgqTP6',
  GTM_ID: '',
  groupFlag: 20,
  firebase: {
    apiKey: 'AIzaSyBYYN-MjbL83VhN0kCCVMj8l7gpJkpr6Ac',
    authDomain: 'icaroweb-ef946.firebaseapp.com',
    databaseURL: 'https://icaroweb-ef946-default-rtdb.firebaseio.com',
    projectId: 'icaroweb-ef946',
    storageBucket: 'icaroweb-ef946.appspot.com',
    messagingSenderId: '738670200312',
    appId: '1:738670200312:web:4d01803860ede59aa1020d',
  },
};



