import { Component, OnInit, Signal, signal } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { environment } from 'src/environment/environment';

import { IAddon } from 'src/app/Interfaces/Models/Addon.interface';
import { IPax } from 'src/app/Interfaces/Models/Pax.interface';
import { AddonService } from 'src/app/services/addon/addon.service';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { ShopcartService } from 'src/app/services/shopcart/shopcart.service';
import { RouterService } from 'src/app/services/router/router.service';
import { ClientService } from 'src/app/services/client/client.service';
import { StepEnum } from 'src/app/models/enums/Step.enum';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { IZone } from 'src/app/models/Zone.model';
import { HotelService } from 'src/app/services/hotel/hotel.service';
import { IHotel } from 'src/app/models/Hotel.model';
import { ITransportation } from 'src/app/models/Transportation.model';
import { TransportationService } from 'src/app/services/transportation/transportation.service';
import { AppService } from 'src/app/services/app/app.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addon.view',
  templateUrl: './addon.view.component.html',
  styleUrls: ['./addon.view.component.css'],
})
export class AddonViewComponent implements OnInit {
  public addonList: IAddon[] = [];
  public addonIndex: number = 0;
  public paxListSelected: IPax[] = [];
  public showPaxDialog: boolean = false;

  public addons$: Observable<IAddon[]> = new Observable();
  public zones: Signal<IZone[]> = this.zoneService.zones;
  public hotels: Signal<IHotel[]> = this.hotelService.hotels;

  public showAddonTransportation: boolean = false;

  constructor(
    private appService: AppService,
    private addonService: AddonService,
    private routerService: RouterService,
    private reservationService: ReservationService,
    private clientService: ClientService,
    private shopcartService: ShopcartService,
    private zoneService: ZonesService,
    private hotelService: HotelService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    try {
      this.routerService.setStep(StepEnum.ADDON);
      this.routerService.setBlock(false);

      this.addonService.cleanState();
      this.addons$ = this.addonService.addons$;

      let shopCart = this.shopcartService.getShopcart();

      for await (let product of shopCart.productList) {
        await this.addonService.getAddonList(product.id);
      }

      this.addonList = this.addonService.getCurrentAddons();

      let locationId = this.appService.getLocation().locationId;
      this.showAddonTransportation =
        environment.ADDONS.TRASNPORTATION.LOCATIONS.some(
          (i) => i == locationId
        );
    } catch (error) {
      console.log(error);
    }
  }

  onSelectAddon(paxList: IPax[], addonIndex: number) {
    this.paxListSelected = [...paxList];
    this.addonIndex = addonIndex;
    this.showPaxDialog = true;
  }

  onSelectAddonCollapse(paxList: IPax[], addonIndex: number) {
    this.paxListSelected = [...paxList];
    this.addonIndex = addonIndex;
    this.onSaveAddon(paxList)
  }

  onSaveAddon(paxList: IPax[]) {
    this.addonList[this.addonIndex].paxList = [...paxList];
    this.addonService.addAddon(this.addonList[this.addonIndex]);
    this.addonList[this.addonIndex].selected = false;
    this.showPaxDialog = false;
  }

  onClosePaxDialog() {
    this.addonList[this.addonIndex].selected = false;
    this.showPaxDialog = false;
  }

  async goToPayment($event: any) {
    try {
      let clientForm = this.clientService.getClient();
      let shopcart = this.shopcartService.getShopcart();

      let reservationId = await this.reservationService.createReservation(shopcart, clientForm);

      if (reservationId) {
        this.reservationService.setReservationId(reservationId);
        this.routerService.goToPayment();
      } else {
        //${this.reservationService.limitForInventory}
        this.toastService.showToast(
          `${this.translateService.instant('SUPPORT.NO_INVENTORY')}`,
          'info',
          { timer: 5000 }
        );
      }
    } catch (error: HttpErrorResponse | any) {
      console.log('AddonViewComponent.goToPayment =>', error);

      if (error && error.status == 400 && error.error.reservationTotal) {
        let msg = `${this.translateService.instant('SUPPORT.TITLE_3')} \n ${this.translateService.instant('SUPPORT.PHONE.' + this.appService.getLocation().locationName)}`;
        this.toastService.showToast(msg, 'info', { timer: 5000 }
        );
      }
    }
  }

  //#region Transportation
  onSaveTransportation(transportation: ITransportation) {
    this.shopcartService.addTransportation(transportation);
  }
  //#endregion
}
