import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpService } from '../http/http.service';
import { AppService } from '../app/app.service';
import { BehaviorSubject } from 'rxjs';
import { IService } from 'src/app/models/Service.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  private serviceList : IService[] = [];
  private serviceSubject : BehaviorSubject<IService[]> = new BehaviorSubject(this.serviceList);
  public  services$ : Observable<IService[]> = this.serviceSubject.asObservable();
  private enableDynamicPrice: boolean = false;

  constructor(
    private httpService : HttpService,
    private appService : AppService,
  ) { }
  

  public async loadServiceList() : Promise<boolean>
  {
    try {
      // means the service list are fulled and it doesnt necessary get another api call
      if(this.serviceList.length > 0){
        return true;
      }

      const {currencyId,sellerId,locationId} = this.appService.getAppConfig();
      const {data,length} = await this.httpService.getProductList({currencyId,sellerId,locationId});

      this.serviceList = data.map(service => {
        return { 
          id : service.productId, 
          name : service.productName, 
          locationId : service.locationId,
          locationName : service.locationName,
          image:service.productImage,
          type : service.productType, 
          selected : false 
        }
      });

      this.serviceSubject.next(this.serviceList);
      return true;
    } catch (error) {
      throw error;
    }
  }

  public setServiceSelected(serviceId : number){
    this.serviceList.map(service => service.selected = false);
    let serviceIndex = this.serviceList.findIndex(service => service.id === serviceId);    
    
    if(serviceIndex < 0){
      return false;
    }

    this.serviceList[serviceIndex].selected = true;
    this.serviceSubject.next(this.serviceList);
    return true;
  } 

  public getEnableDynamicPrice()
  {
    return this.enableDynamicPrice;
  }

  public setEnableDynamicPrice(enable:boolean)
  {
    this.enableDynamicPrice = enable;
  }
}
